import { default as _91id_93bZ5bKPtmy6Meta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/[id].vue?macro=true";
import { default as _91id_93y7RPZ0QGjXMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/embed/[id].vue?macro=true";
import { default as publice0Rpr5lC4tMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/public.vue?macro=true";
import { default as index3FXHWJu2N5Meta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/index.vue?macro=true";
import { default as maintenanceFhlpfT3hKLMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/maintenance.vue?macro=true";
import { default as sandboxVZ7hHXIzxmMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/sandbox.vue?macro=true";
import { default as signinFwaGXNK4SCMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signin.vue?macro=true";
import { default as signoutuP5lxwlRJMMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signout.vue?macro=true";
import { default as signupsQ6Eh8simbMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signup.vue?macro=true";
import { default as indexLmwdhVV8zOMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics/index.vue?macro=true";
import { default as sessionsGRv8x7AMuDMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue?macro=true";
import { default as analytics3biLxj3QhAMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics.vue?macro=true";
import { default as _91id_931ENOVG41BAMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue?macro=true";
import { default as createsmzCIbrwIjMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/create.vue?macro=true";
import { default as _91id_93IPjI3Dgo9cMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue?macro=true";
import { default as index0ACcPnP7E6Meta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/index.vue?macro=true";
import { default as documentsb9f8GFpqYXMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/documents.vue?macro=true";
import { default as indexd7eOFLamwUMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/index.vue?macro=true";
import { default as postcheckoutDIwQAGQTcBMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/postcheckout.vue?macro=true";
import { default as resetPasswordZwrUY7ySCUMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/resetPassword.vue?macro=true";
import { default as indexfxieRisYp9Meta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/index.vue?macro=true";
import { default as integrationsgFo7XfmHu4Meta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/integrations.vue?macro=true";
import { default as legalPLZfnF3FDIMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/legal.vue?macro=true";
import { default as organizationMth9dvrw83Meta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/organization.vue?macro=true";
import { default as planDd0XpDBNXQMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/plan.vue?macro=true";
import { default as settingsToKuTTde8AMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings.vue?macro=true";
import { default as supportEpNxyPQYhjMeta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/support.vue?macro=true";
import { default as teamvqJYjPtaE5Meta } from "/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/team.vue?macro=true";
export default [
  {
    name: "chat-id___en",
    path: "/chat/:id()",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-id___de",
    path: "/de/chat/:id()",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___en",
    path: "/chat/embed/:id()",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___de",
    path: "/de/chat/embed/:id()",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-public___en",
    path: "/chat/public",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "chat-public___de",
    path: "/de/chat/public",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___de",
    path: "/de/maintenance",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___en",
    path: "/sandbox",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___de",
    path: "/de/sandbox",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "signin___en",
    path: "/signin",
    meta: signinFwaGXNK4SCMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___de",
    path: "/de/signin",
    meta: signinFwaGXNK4SCMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signout___en",
    path: "/signout",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signout___de",
    path: "/de/signout",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/signup",
    meta: signupsQ6Eh8simbMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "signup___de",
    path: "/de/signup",
    meta: signupsQ6Eh8simbMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: analytics3biLxj3QhAMeta?.name,
    path: "/user/analytics",
    meta: analytics3biLxj3QhAMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___en",
    path: "",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___en",
    path: "sessions",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: analytics3biLxj3QhAMeta?.name,
    path: "/de/user/analytics",
    meta: analytics3biLxj3QhAMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___de",
    path: "",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___de",
    path: "sessions",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-chatbots-chat-id___en",
    path: "/user/chatbots/chat/:id()",
    meta: _91id_931ENOVG41BAMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-chat-id___de",
    path: "/de/user/chatbots/chat/:id()",
    meta: _91id_931ENOVG41BAMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___en",
    path: "/user/chatbots/create",
    meta: createsmzCIbrwIjMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___de",
    path: "/de/user/chatbots/create",
    meta: createsmzCIbrwIjMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___en",
    path: "/user/chatbots/edit/:id()",
    meta: _91id_93IPjI3Dgo9cMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___de",
    path: "/de/user/chatbots/edit/:id()",
    meta: _91id_93IPjI3Dgo9cMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___en",
    path: "/user/chatbots",
    meta: index0ACcPnP7E6Meta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___de",
    path: "/de/user/chatbots",
    meta: index0ACcPnP7E6Meta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___en",
    path: "/user/documents",
    meta: documentsb9f8GFpqYXMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___de",
    path: "/de/user/documents",
    meta: documentsb9f8GFpqYXMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "/user",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user___de",
    path: "/de/user",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___en",
    path: "/user/postcheckout",
    meta: postcheckoutDIwQAGQTcBMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___de",
    path: "/de/user/postcheckout",
    meta: postcheckoutDIwQAGQTcBMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___en",
    path: "/user/resetPassword",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___de",
    path: "/de/user/resetPassword",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: settingsToKuTTde8AMeta?.name,
    path: "/user/settings",
    meta: settingsToKuTTde8AMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings___en",
    path: "",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___en",
    path: "integrations",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___en",
    path: "legal",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___en",
    path: "organization",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsToKuTTde8AMeta?.name,
    path: "/de/user/settings",
    meta: settingsToKuTTde8AMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings___de",
    path: "",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___de",
    path: "integrations",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___de",
    path: "legal",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___de",
    path: "organization",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___de",
    path: "plan",
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-support___en",
    path: "/user/support",
    meta: supportEpNxyPQYhjMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-support___de",
    path: "/de/user/support",
    meta: supportEpNxyPQYhjMeta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-team___en",
    path: "/user/team",
    meta: teamvqJYjPtaE5Meta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  },
  {
    name: "user-team___de",
    path: "/de/user/team",
    meta: teamvqJYjPtaE5Meta || {},
    component: () => import("/codebuild/output/src734843636/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  }
]